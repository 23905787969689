@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(https://fonts.googleapis.com/css2?family=Delicious+Handrawn&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko&display=swap); 
@import url(https://fonts.googleapis.com/css2?family=Alkatra&display=swap);

@layer base {
  html {
    @apply text-white;
  }
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background: #232526;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #141414, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
